import React, { useState } from 'react'

import dottedBg from '../../../assets/dotted_black_background.jpg';

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null)

    const faqs = [
        {
            question: 'How can I find scholarships for studying abroad?',
            answer: 'Tackoom is a platform where counselors sign up to counsel students. Our counselors are handpicked and equiped to provide you with guidance in scholarships.'
        },
        {
            question: 'Who can use Tackoom?',
            answer: 'Any student seeking for career guidance and who plans to study either in Nepal or abroad can use Tackoom.'
        },
        {
            question: 'Who is behind this platform?',
            answer: 'Tackoom is a student led platform where students meet their perfect counselors who guide them to their path of success in career with ethical and curated counseling.'
        },
        {
            question: 'What do I need to get started?',
            answer: 'Your interest in growing yourself is all that Tackoom requires from you.'
        }
    ];

    const toggleFAQHandler = (index) => {
        setActiveIndex(activeIndex === index ? null : index)
    }
    return (
        <main className='faq-container'>
            <div className='img-wrapper'>
                <img className='faq-dot-svg' src={dottedBg} alt='faq-dotted-background' />
            </div>
            <h1>FAQ</h1>
            <div className='faq-mini-container'>
                <section className='faq-section'>
                    {faqs.map((faq, index) => (
                        <article key={index} className='each-faq'>
                            <div onClick={() => toggleFAQHandler(index)}>
                                <h3>{faq.question}</h3>
                                <svg width="10" height="7" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 .799l4 4 4-4" stroke="#F47B56" strokeWidth="2" fill="none" fillRule="evenodd" />
                                </svg>
                            </div>
                            <p className={(activeIndex === index) ? 'faq-open' : ''}>{faq.answer}</p>
                        </article>
                    ))}
                </section>
            </div>
        </main>
    )
}

export default FAQ