import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Register.css';
// Phone Number
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
// // Logo
import logo from '../../assets/navbar/logo.png';

// // Icons
import { Icon } from 'react-icons-kit';
import { loading } from 'react-icons-kit/ikons/loading'
// //Encrypt
import { SHA256 } from 'crypto-js';
import { getRequest, postRequest, setRefreshToken } from '../../requestAndLocalStorage/requestAndLocalStorage';
import { toast } from 'react-toastify';
import { getPasswordStrength, getPasswordStrengthColor } from '../../tools';
import PasswordSetter from '../../components/passwordSetter/passwordSetter';
import GLoginOut from '../googleOauth';


// main component starts here
const RegisterUser = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // fields
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState(searchParams.get('email') || '');
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [OTP, setOTP] = useState();
  // Errros
  const [fieldErrors, setFieldErrors] = useState({});
  // Page
  const [pageNumber, setPageNumber] = useState(0);
  const [firstDisabled, setFirstDisabled] = useState(true);
  const [OTPLoading, setOTPLoading] = useState(false);

  const [hasErrors, setHasErrors] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOTPChange = (e) => {
    if (e.target.value.length===0) return setOTP('');
    if (parseInt(e.target.value)) {
      setOTP(parseInt(e.target.value));
    }
  }

  function encryptPassword(password) {
    const hash = SHA256(password)
    return hash.toString();
  }

  useEffect(() => {
    setHasErrors(validateForm());
  }, [firstName, lastName, email, password, phoneNumber, confirmPassword])


  const handleEmailCheck = (e) => {
    e.preventDefault();
    getRequest('/api/data/findUser/email', {
      Email: email
    })
      .then((response) => {
        if (response.data.length === 0) {
          setPageNumber(1);
          setFieldErrors(prev=>{
            return {...prev, email:''}
          })
        }
        else {
          setFieldErrors({ email: "User with this email already exists." })
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const validateForm = () => {

    const errors = {};

    // Check for empty fields
    if (firstName.trim() === '') {
      errors.firstName = 'First Name is required';
    }

    if (lastName.trim() === '') {
      errors.lastName = 'Last Name is required';
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email.trim())) {
      errors.email = 'Email is required';
      setConfirmEmail(false);
    }
    else {
      setConfirmEmail(true);
    }

    if (password.trim() === '') {
      errors.password = 'Password is required';
    }

    if (phoneNumber === '') {
      errors.phoneNumber = 'Phone Number is required';
    }

    // Password validation rules
    // const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}$/;
    if (!password) {
      errors.password = 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one numerical digit, and one special character (@#$%^&+=)';
    }

    // Password and Confirm Password must match
    if (!confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    // Phone number validation
    if (phoneNumber && phoneNumber.length !== 0 && !isPossiblePhoneNumber(phoneNumber) && !isValidPhoneNumber(phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number';
    }

    // if (!isValidPhoneNumber(phoneNumber)) {
    //   errors.phoneNumber = 'Phone number is not valid';
    // }

    console.log(errors);
    if (errors.email || errors.password || errors.confirmPassword) {
      setFirstDisabled(true);
    }
    else setFirstDisabled(false);

    if (Object.keys(errors).length > 0) {
      // setFieldErrors(errors);
      return false;
    }
    return true;
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    // return;
    const errors = {};
    try {
      setIsLoading(true);

      postRequest('/api/auth/registration/user', {
        Email: email,
        Password: encryptPassword(password),
        Name: firstName + ' ' + lastName,
        Phone: phoneNumber.substring(4)
      })
        .then(response => {
          setIsLoading(false);
          setPageNumber(2);
          // toast.info('Check your inbox for the verification link!');
          // navigate('/');
        })
        .catch(error => {
          const err = {};
          if (error.response.data === "Email is already in use") {
            err.email = "This email is already in use";
            setPageNumber(0);
            setFieldErrors(err);
          }
          setIsLoading(false);
        });

    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        errors.email = 'Email is already in use';
      } else if (error.code === 'auth/invalid-email') {
        errors.email = "Enter a valid email address"
      } else {
        console.error('Registration error', error);
        // Handle other registration errors
      }

      setFieldErrors(errors);
    }
  };


  const verifyOTP = (e) => {
    e.preventDefault();
    setOTPLoading(true);
    postRequest('/api/data/registration/user/verify', {
      Email: email,
      Name: firstName + ' ' + lastName,
      OTP: OTP
    })
      .then(response => {
        postRequest('/api/auth/login', {
          Email: email,
          Password: encryptPassword(password),
        })
          .then(response => {
            setRefreshToken(response.data.refreshToken);
            toast.success('Logged in successfully');
  
            navigate('/');
            window.location.reload();
          })
          .catch(err => {
            setOTPLoading(false);
            if (err.response.data === "Invalid User" || err.response.data === 'Invalid Password') {
              toast.error('Invalid OTP', { position: 'top-left' });
              // setEmailError("User not found");
            }
            else {
              console.error(err);
            }
            setIsLoading(false);
          });
      })
      .catch(err => {
        setFieldErrors({ OTP: "Invalid OTP" });
        setOTPLoading(false);
      })
  }


  const resendOTP = (e) => {
    e.preventDefault();
    postRequest('/api/data/registration/user/resend_OTP', {
      Email: email,
      Name: firstName + ' ' + lastName
    }).
      then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      })
  }






  return (
    <div className="register-container">

      <div className="register-form-container">
        <div className="logo-container">
          <img src={logo} onClick={() => navigate('/')} alt="Logos" className="logo" />
        </div>
        <h2>Create an User Account</h2>

        {/* <GLoginOut /> */}
        {/* <input type='range' value={(pageNumber + 1) * 100 / 3} className='register-progress-slider'></input> */}
        { pageNumber === 0 ?
          /* FIRST PAGE - EMAIL AND PASSWORD */
          <>
            <div className='registration-body'>
              <div className='registry-email-field'>
                <input
                  type="email"
                  autoComplete='new-email'
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
                {(email.length !== 0) ? <div className={"correct-password-" + (confirmEmail ? "yes" : "no")}></div> : <></>}
              </div>
              {fieldErrors.email && <p className="error">{fieldErrors.email}</p>}
              <PasswordSetter
                setPasswordCallback={(e) => setPassword(e)}
                setPasswordConfirmCallback={(e) => {
                  console.log(e);
                  setConfirmPassword(e)
                }}
              />
              {fieldErrors.password && <p className="error">{fieldErrors.password}</p>}
              {fieldErrors.confirmPassword && <p className="error">{fieldErrors.confirmPassword}</p>}

              <p>
                Already have an account? <Link to="/login">Sign in</Link>
              </p>
            </div>
            <div className='registration-nav-button-container'>
              <div></div>
              <button onClick={handleEmailCheck} disabled={firstDisabled}>Next</button>
            </div>
          </>

        : pageNumber === 1 ?
          /* SECOND PAGE - FIRST NAME, LAST NAME, PHONE */
          <>
            <div className='registration-body'>
              <div className="name-fields">
                <input
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  />
                {fieldErrors.firstName && <p className="error">{fieldErrors.firstName}</p>}
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={handleLastNameChange}
                />
                {fieldErrors.lastName && <p className="error">{fieldErrors.lastName}</p>}
              </div>
              
              <div className="phone-number-field">
                <PhoneInput
                  className='phone-input-select'
                  defaultCountry='NP'
                  country="NP"
                  type="tel"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  />
              </div>
              {fieldErrors.phoneNumber && <p className="error">{fieldErrors.phoneNumber}</p>}
            </div>
            <div className='registration-nav-button-container'>
              <button onClick={() => setPageNumber(0)}>Previous</button>
              <button className="register-button" onClick={(e) => handleFormSubmit(e)} disabled={isLoading || !hasErrors}>
                  {isLoading ? (
                    <Icon className='loading-icon' icon={loading} size={20} />
                  ) : (
                    'Register'
                  )
                }
              </button>
              {/* <button onClick={(e) => handleFormSubmit(e)}>Next</button> */}
            </div>
          </>
        : pageNumber === 2 ?
          /* THIRD PAGE - OTP VERIFICATION */
          <>
            <div className='registration-body'>
              <p>We sent a OTP code to {email} for verification. Check your mail to receive your OTP.</p>
              <input
                type="text"
                className='user-register-OTP-input'
                placeholder="OTP Code"
                value={OTP}
                onChange={handleOTPChange}
              />
              {fieldErrors.OTP && <p className="error">{fieldErrors.OTP}</p>}

              <div className='register-OTP-resend-container'>
                <p>Didn't receive a mail?</p>
                <button onClick={resendOTP}>Send OTP</button>
              </div>
            </div>
            <div className='registration-nav-button-container'>
              <div></div>
              {/* <button onClick={() => setPageNumber(1)}>Previous</button> */}
              <button className="register-button" onClick={verifyOTP} disabled={ (OTP ? OTP.toString().length < 3 : true) || OTPLoading }>
                  {OTPLoading ? (
                    <Icon className='loading-icon' icon={loading} size={20} />
                  ) : (
                    'Verify'
                  )
                }
              </button>
            </div>
          </>
        : <></>}

        {/* <form onSubmit={handleFormSubmit}>
          
        </form> */}
      </div>
    </div>
  );
};

export default RegisterUser;
